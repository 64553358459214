.App {
  text-align: center;
}

.logo {
  width: 100%;
  max-width: 350px;
  margin: 0 auto;
}

.legend {
  display: block;
}

.programas {
  width: 100%;
  margin: auto;
  max-width: 400px;
  position: absolute;
  top: 50%;
  left: 0;
  right: 0;
  bottom: 50%;
}